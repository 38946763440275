<template>
  <div style="display: inline-block;vertical-align: middle;height: 100%;width: 100%">
    <v-chart className="chart" :option="option"/>
  </div>
</template>

<script>
import {use} from "echarts/core";
import {CanvasRenderer} from "echarts/renderers";
import {BarChart} from "echarts/charts";
import {
  TitleComponent,
  TooltipComponent,
  LegendComponent,
  GridComponent,
  ToolboxComponent,
} from "echarts/components";
import VChart, {THEME_KEY} from "vue-echarts";
import {ref, defineComponent} from "vue";

use([
  CanvasRenderer,
  BarChart,
  TitleComponent,
  TooltipComponent,
  LegendComponent,
  GridComponent,
  ToolboxComponent,
]);
export default {
  name: "StatisticBar",
  props: {
    chartData:{
      required:true
    },
    x_Axis:{},
    _legend:{},

  },
  components: {
    VChart
  },
  provide: {
    [THEME_KEY]: "light"
  },
  data() {
    return {
      option : {
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'shadow'
          }
        },
        legend: {},
        grid: {
          left: '3%',
          right: '4%',
          bottom: '3%',
          containLabel: true
        },
        xAxis: [
          {}
        ],
        yAxis: [
          {
            type: 'value'
          }
        ],
        series: [
        ]
      }
    }
  },
  mounted() {
    this.option.xAxis[0]=this.x_Axis;
    this.option.legend=this._legend
    this.option.series=this.chartData;

  },
  watch:{
    "chartData":{
      deep:true,
      handler(){
        this.option.series=this.chartData;
      }
    }
  }
}
</script>

<style scoped>
.statistic-bar{
  min-width: var(--minWidth,unset);
}
</style>
